import React from 'react'
import Head from '@fs/zion-head'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { getCustomImage, isConferencePromo } from '../helpers'

const getProviderData = () => ({
  '@type': 'Organization',
  name: 'FamilySearch',
  url: 'https://www.familysearch.org',
})

const getOffersData = (eventUrl) => ({
  '@type': 'Offer',
  category: 'free',
  price: '0',
  priceCurrency: 'USD',
  availability: 'https://schema.org/InStock',
  url: eventUrl || '',
})

const getPerformerData = (creators) => {
  if (!creators || creators.length === 0) return undefined
  const { name, url, profileImage } = creators[0]
  return {
    '@type': 'Person',
    name: name || i18n.t('event.structured-data.unknown-speaker', 'Unknown Speaker'),
    url: url || '',
    image: profileImage?.publicUrl || '',
  }
}

const transformEvents = (items, provider) =>
  items?.map((item) => {
    const [t] = useTranslation()
    const { title, description, date, conferenceYear, url, image, creators } = item

    return isConferencePromo(item)
      ? {
          '@type': 'Event',
          name: title || t('event.structured-data.untitled-event', 'Untitled Event'),
          description: description || t('event.structured-data.no-description-available', 'No description available'),
          startDate: date || conferenceYear || '',
          url: url || '',
          image: getCustomImage(image),
          location: {
            '@type': 'VirtualLocation',
            url: url || '',
          },
          organizer: provider,
          performer: getPerformerData(creators),
          offers: getOffersData(url),
        }
      : []
  }) || []

export default function EventStructuredData({ data }) {
  const [t] = useTranslation()
  if (!data || !data.content || data.content.length === 0) return null

  const provider = getProviderData()
  const events = transformEvents(data.content[0]?.items || [], provider)

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'EventSeries',
    name: data.name || t('event.structured-data.upcoming-events', 'Upcoming Events'),
    description: data.description || t('event.structured-data.no-description-available', 'No description available'),
    url: data.url || '',
    organizer: provider,
    subEvent: events,
  }

  return (
    <Head>
      <script type="application/ld+json" data-test-id="structured-data">
        {JSON.stringify(structuredData, null, 2)}
      </script>
    </Head>
  )
}
